import { useNavigate } from 'react-router-dom'
import { Stack } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { CustomSwitch } from './CustomSwitch'
import { EventHeaderComponent } from '../EventPage'
import {
  convertIntTo2DecimalFloat,
  getMyContributionAmount,
  EVENT_TYPES,
  isCreator,
} from '../../utils/utils'
import styles from '../../styles/ActiveEventComponent.module.css'

const calculateHoursUntilDeadlineOccurs = (event) => {
  if (!event) return null
  const deadline = new Date(event.deadline)
  const now = new Date()
  const diffInMilliseconds = deadline - now
  const diffInHours = Math.floor(diffInMilliseconds / (1000 * 60 * 60))
  return diffInHours
}


const ActiveEventComponent = (props) => {
  const {
    event,
    currentEthAddress,
    switchDisabled,
    switchOptedIn,
    switchOnClick,
  } = props

  const navigate = useNavigate()

  return (
    <>
      <div className={styles.EventContainer}>
        <Stack
          direction={'column'}
          className={styles.EventSummaryContainer}
          spacing={{ xs: 2 }}
        >
          <EventHeaderComponent
            event={event}
            switchDisabled={switchDisabled}
            switchOptedIn={switchOptedIn}
            switchOnClick={switchOnClick}
            currentEthAddress={currentEthAddress}
          />
          <div className={styles.EventContributionAmount}>
            {`$${convertIntTo2DecimalFloat(
              getMyContributionAmount(event, currentEthAddress)
            )}`}
          </div>
          <div
            className={styles.EventViewDetailsButton}
            onClick={() => {
              navigate(`/campaign/${event.id}`)
            }}
          >
            VIEW DETAILS
          </div>
        </Stack>
      </div>
    </>
  )
}

export { ActiveEventComponent }
